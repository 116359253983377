import React from 'react';
import axios from 'axios';
import { capitalizeFirstLetterOfEachWord, formatPrice } from '../utils'; // Adjust the import path as needed
import { Link } from 'react-router-dom';
import './ProductBox.css';
import { useAuth } from '../AuthContext';

export default function ProductBox({ product, onClick }) {
  const { user } = useAuth();

  const addInteraction = async () => {
    try {
    if (user) {
      const idToken = await user.getIdToken();
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/user/interaction`, 
        { productId: product._id, productLink: product.link },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
    } else {
       // interaction if not logged in
       await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/user/interaction/anon`, 
        { productId: product._id, productLink: product.link },
      );
       }
      } catch (error) {
        console.error('Error adding interaction:', error);
      }
  }

  return (
    <div className='product-box product-box-container' onClick={onClick ? () => onClick() : undefined}>

    <Link onClick={addInteraction} className='product-page-link' to={`/product-page/${product._id}`}>
     <div>
     <img 
         src={product.image} 
         alt={product.name} 
         onError={(e) => { e.target.src = '/logo2.png'; console.error('Image not found:', product.link); }} 
     />
      <h2>{capitalizeFirstLetterOfEachWord(product.name)}</h2>
     <div>
         {product.prices.discounted ?
         <div>
         <span>Now: {`${formatPrice(product.prices.discounted)} ${product.currency}`}</span>
         </div>
         : 
         <div>
         <span className='original-price original-price-only'>Now: {`${formatPrice(product.prices.original)} ${product.currency}`}</span>
         </div>
         }
     </div>
     <div className='store-name-container'>
         <img 
         className='store-logo'
         src={product.storeLogo} 
         alt={product.store} 
         onError={(e) => { e.target.src = '/logo2.png'; console.error('Image not found:', product.link); }} 
     />
     </div>
     </div>
     </Link>
     </div>
  )
}
